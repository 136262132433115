export default (context) => {

    console.log('context::::: ', context)

    if (context.route && context.route.path.toString().includes('/menu')) {
        console.log('we are on menu')
        // redirect('https://krystal.olo.com/?stay=y')
    }


    if (context.route && context.route.path.toString().includes('/order')) {
        if (context.route.path.toString().includes('/product')) {
            if (context.store.state.location) {
                //there is a location
                console.log('store.state.location.slug::: ', context.store.state.location.slug)
                if (context.store.state.location.slug) {
                    //there is a slug
                    return context.redirect(`${context.store.state.location.slug}products/${context.route.query?.id}/?stay=y`)
                    // redirect(`${store.state.location.slug}products/74714861/?stay=y`)
                }
            }
        } else {
            console.log('we are on order  https://order.krystal.com')
            console.log('store:::xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx ', context.store.state.location)
            if (context.store.state.location) {
                // there is a location
                if (context.store.state.location.slug) {
                    //there is a slug
                    return context.redirect(`${context.store.state.location.slug}?stay=y`)
                    // redirect(`${context.store.state.location.slug}?stay=y`)
                }
            } else {
                return context.redirect('/')
            }
        }
    }


    if (context.route && context.route.path.toString().includes('/account')) {
        console.log('we are in an account route')
        if (process.browser.window) {
            console.log('process.browser:::: ', process.browser)
            process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
        } else {
            console.log('no proccess browser here')
            return context.redirect(302, '/')
        }
        
        // return context.redirect(302, '/')
        // context.store.commit('setRedirect', '/')
    }
    if (context.route && context.route.path.toString().includes('/checkout')) {
        console.log('we are in an checkout route')
        if (process.browser.window) {
            process.browser.window.onNuxtReady(() => { process.browser.window.$nuxt.$router.push('/') })
        } else {
            return context.redirect(302, '/')
        }
        // return context.redirect(302, '/')
    }

}


// export default ({ redirect, store, route }) => {

//     if (route && route.path.toString().includes('/menu')) {
//         console.log('we are on menu')
//         // redirect('https://krystal.olo.com/?stay=y')
//     }
//     if (route && route.path.toString().includes('/order')) {
//         if (route.path.toString().includes('/product')) {
//             console.log('route::::xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx ', route)
//             if (store.state.location) {
//                 //there is a location
//                 console.log('store.state.location.slug::: ', store.state.location.slug)
//                 if (store.state.location.slug) {
//                     //there is a slug
//                     redirect(`${store.state.location.slug}products/${route.query?.id}/?stay=y`)
//                     // redirect(`${store.state.location.slug}products/74714861/?stay=y`)
//                 }
//             }
//         } else {
//             console.log('we are on order  https://order.krystal.com')
//             console.log('store:::xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx ', store.state.location)
//             if (store.state.location) {
//                 // there is a location
//                 if (store.state.location.slug) {
//                     //there is a slug
//                     redirect(`${store.state.location.slug}?stay=y`)
//                 }
//             } else {
//                 redirect('/')
//             }
//         }
//     }

//     if (route && route.path.toString().includes('/account')) {
//         console.log('we are in an account route')
//         console.log('context::: ', context)
//         return redirect('/')
//     }
//     if (route && route.path.toString().includes('/checkout')) {
//         console.log('we are in an checkout route')
//         // redirect('/')
//     }

// }